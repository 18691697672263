import PropTypes from 'prop-types'
import React from 'react'

import Project from '../components/Project';
import projectData from '../data/projects';

import pic01 from '../images/pic01.jpg'
import resume from '../images/resume.pdf'
import headshot from '../images/Nick_Clear_Headshot.jpg';
import aboutPic from '../images/lakeview.jpg';


class Main extends React.Component {
  render() {
    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
      ></div>
    )

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
        <article
          id="about"
          className={`${this.props.article === 'about' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">About</h2>
          <span className="image main">
            <img src={aboutPic} alt="lake" />
          </span>
            <p>Hello world, I'm a full-stack engineer with track record of crafting polished, user-friendly applications. My experience as a Software Engineer at the University of Phoenix complmented with certification from the Coding Bootcamp at UC-Irvine, provides a strong skillset in DevOps and full-stack development.</p>
            <p>I’m a converted craft brewer turned full-stack engineer and currently brewing up React applications, microservices, and cloud infrastructure as a Software Engineer at the University of Phoenix. I decided to transfer my brewing skills to the software industry after experiencing the frustrations of using inefficient brewing software and web applications. Seeking solutions, I developed an ardent interest in computer programming, enrolled in the Coding Boot Camp at UC-Irvine, and created Beer Peers, a craft-beer recipe sharing web application.</p>
            <p>I spend my free time rewatching The Office, exploring and enjoying the great outdoors, and continuing my quest for the best bibimbap in the area. If you’d like to discuss any topic from brewing sciences, Korean restaurant recommendations, or software development please feel free to contact me at: nickclear22@gmail.com.</p>
            <p>By the way, connect with me on <a href="https://www.linkedin.com/in/nickclear/" target="_blank">LinkedIn</a>!</p>
            <ul className="icons">
              <li><a href="https://www.linkedin.com/in/nickclear/" target="_blank" className="icon fa-linkedin"><span className="label">LinkedIn</span></a></li>
              <li><a href="https://github.com/nmclear" target="_blank" className="icon fa-github"><span className="label">GitHub</span></a></li>
              <li><a href="mailto:nickclear22@gmail.com" className="icon fa-at"><span className="label">Email</span></a></li>
              <li><a href="https://twitter.com/NickClear" target="_blank" className="icon fa-twitter"><span className="label">Twitter</span></a></li>
            </ul>
            {close}
        </article>

        <article
          id="work"
          className={`${this.props.article === 'work' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Work</h2>
          {
            projectData.map((project, i) => (
             <Project
               key={`project-${i}`}
               title={project.title}
               image={project.image}
               summary={project.summary}
               live={project.live}
               repo={project.repo}
             />
            ))
           }
          <ul className="icons">
            <li><a href="https://www.linkedin.com/in/nickclear/" target="_blank" className="icon fa-linkedin"><span className="label">LinkedIn</span></a></li>
            <li><a href="https://github.com/nmclear" target="_blank" className="icon fa-github"><span className="label">GitHub</span></a></li>
            <li><a href="mailto:nickclear22@gmail.com" className="icon fa-at"><span className="label">Email</span></a></li>
            <li><a href="https://twitter.com/NickClear" target="_blank" className="icon fa-twitter"><span className="label">Twitter</span></a></li>
          </ul>
          {close}
        </article>

        <article
          id="resume"
          className={`${this.props.article === 'resume' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Resume</h2>
          <object data={resume} type="application/pdf" width="100%" height="780px">
            <p>Your web browser doesn't have a PDF plugin.
            Instead you can <a href={resume} target="_blank">click here to
            download the PDF file.</a></p>
          </object>
          <p style={{textAlign: "center"}}>Currently located in Phoenix, AZ</p>
          <p style={{textAlign: "center"}}>Not looking to relocate at this time</p>
          <ul className="icons">
            <li><a href="https://www.linkedin.com/in/nickclear/" target="_blank" className="icon fa-linkedin"><span className="label">LinkedIn</span></a></li>
            <li><a href="https://github.com/nmclear" target="_blank" className="icon fa-github"><span className="label">GitHub</span></a></li>
            <li><a href="mailto:nickclear22@gmail.com" className="icon fa-at"><span className="label">Email</span></a></li>
            <li><a href="https://twitter.com/NickClear" target="_blank" className="icon fa-twitter"><span className="label">Twitter</span></a></li>
          </ul>
          {close}
        </article>

        <article id="contact" className={`${this.props.article === 'contact' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">Contact</h2>
          <form method="post" action="https://formspree.io/nickclear22@gmail.com">
            <div className="field half first">
              <label htmlFor="name">Name</label>
              <input type="text" name="name" id="name" />
            </div>
            <div className="field half">
              <label htmlFor="email">Email</label>
              <input type="text" name="email" id="email" />
            </div>
            <div className="field">
              <label htmlFor="message">Message</label>
              <textarea name="message" id="message" rows="4"></textarea>
            </div>
            <ul className="actions">
              <li><input type="submit" value="Send Message" className="special" /></li>
              <li><input type="reset" value="Reset" /></li>
            </ul>
          </form>
          <ul className="icons">
            <li><a href="https://www.linkedin.com/in/nickclear/" target="_blank" className="icon fa-linkedin"><span className="label">LinkedIn</span></a></li>
            <li><a href="https://github.com/nmclear" target="_blank" className="icon fa-github"><span className="label">GitHub</span></a></li>
            <li><a href="mailto:nickclear22@gmail.com" className="icon fa-at"><span className="label">Email</span></a></li>
            <li><a href="https://twitter.com/NickClear" target="_blank" className="icon fa-twitter"><span className="label">Twitter</span></a></li>
          </ul>
          {close}
        </article>
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
