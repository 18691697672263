import React from 'react'
import PropTypes from 'prop-types'

const Footer = (props) => (
    <footer id="footer" style={props.timeout ? {display: 'none'} : {}}>
    <p className="copyright">&copy; Collab: <a href="https://github.com/nmclear" target="_blank">GitHub</a> - Connect: <a href="https://www.linkedin.com/in/nickclear/" target="_blank">LinkedIn</a> - Chat: <a href="mailto:nickclear22@gmail.com">nickclear22@gmail.com</a></p>
    </footer>
)

Footer.propTypes = {
    timeout: PropTypes.bool
}

export default Footer
