import React from 'react'

const Project = props => {
    return (
        <div className="projectCard">
            <h3 style={{textAlign: "center", fontSize: "22px"}}>{props.title}</h3>
            <span className="image main">
                <a href={props.live} target="_blank">
                <img src={props.image} alt={`image-${props.title}`} />
                </a>
            </span>
            <p>{props.summary}</p>
            <p style={{textAlign: "center", fontSize: "16px"}}>Demo: <a href={props.live} target="_blank">Live</a> - <a href={props.repo} target="_blank">Repo</a></p>
            <hr />
        </div>
    )

}

export default Project