import beerPeers from '../images/beer-peers.jpg';
import ChaCha from '../images/ChaCha_preview.jpg';
import CookieFinder from '../images/cookie-finder.jpg';
import Mario from '../images/mario-memory-game.jpg';
import Loiter from '../images/loiter_landing.jpg';
import Bamazon from '../images/bamazon.jpg';
import WordGuess from '../images/word-guess-game.jpg';
import GIFBlazer from '../images/gifMeBlazer.jpg';
import FriendFinder from '../images/friend-finder.jpg';
import Hogwarts from '../images/hogwartsrailways.jpg';
import BrewingTrivia from '../images/brewingtrivia.jpg';
import BirdCatcher from '../images/birdcatcher.jpg';
import Liri from '../images/lirinode.jpg';
import WomenOfWine from '../images/women-of-wine.jpg';
import TapList from '../images/tap_list.jpg';

const projects = [

    {
        title: 'Beer Peers',
        image: beerPeers,
        summary: 'A social platform for brewers to engage and share recipes. Beer peers is a full-stack MERN application. The React library is utilized to handle UI and simplify user manipulations. The non-relational database MongoDB was utilized to handle unique and complex data storage of beer recipes. Server side was developed with a Node environment and express routing.',
        live: 'https://www.beerpeersapp.com/',
        repo: 'https://github.com/nmclear/beer-peers'
    },
    {
        title: 'Tap List Mobile App',
        image: TapList,
        summary: `Create a personalized tap list by swiping through today's most popular brews! Tap List is a iOS and Android app built with React Native. Components were designed with React Native Elements toolkit and swipe animations are handled via React Native Animated API. Navigation and screen communication are by React Native Router Flux. Application state management via Redux.`,
        live: 'https://drive.google.com/file/d/1jANw_e6UEe3jZb1EHl9vhF3eN2ZxTYRa/view?usp=sharing',
        repo: 'https://github.com/nmclear/tap-list'
    },
    {
        title: 'Women Of Wine TC',
        image: WomenOfWine,
        summary: 'Promo page for Women of Wine TC Events. Built with React and Material UI.',
        live: 'https://www.womenofwinetc.com/',
        repo: 'https://www.womenofwinetc.com/'
    },
    {
        title: 'ChaCha Savings',
        image: ChaCha,
        summary: 'Google Chrome Extension. Save more. Buy less. Prompts you to invest before online spending. ChaCha Savings monitors your browsing tab URL and checks if you are on a checkout or cart page. If you load a checkout/cart page, ChaCha will prompt you to save your money and provide links to popular investing sites. If you do not want to go to one of the savings link, click outside of the pop-up and proceed with your purchase. ChaCha Savings is handy for anyone who is an impulse online shopper and is seeking to save more money.',
        live: 'https://www.chachasavings.com/',
        repo: 'https://www.chachasavings.com/'
    },
    {
        title: 'Cookie Finder',
        image: CookieFinder,
        summary: 'Have you ever wondered the streets looking for some girl scout cookies? Well wander no more! Cookie Finder is a platform for Girl Scouts to list products for sale and where they are selling the cookies. Cookie FInder is a full-stack application utilizing Google Maps Platform for location services and SendGrid API for order details and confirmation. Handlebars was used for dynamic rendering. Express, Node, and MySQL make up the backend structure.',
        live: 'https://cookie-seller-app.herokuapp.com/',
        repo: 'https://github.com/nmclear/Cookie_Finder'
    },
    {
        title: 'Mario Memory Game',
        image: Mario,
        summary: 'Test your memory with this Mario Kart Memory Game! Click on your favorites Mario Kart racers, but make sure not to click on one more than once. Mario Memory Game is a frontend application built using the React library, to handle the dynamic game pieces and data states. Can you select all 12 pieces without repeating?',
        live: 'https://nmclear.github.io/memory-game/',
        repo: 'https://github.com/nmclear/memory-game'
    },
    {
        title: 'Loiter',
        image: Loiter,
        summary: 'Loiter is an event search platform to find popular events with nearby eateries. Front-end application utilizing Materialize framework and jQuery. The SeatGeek API is used for event and ticket information. The Here API is used for location services and nearby eateries.',
        live: 'https://nmclear.github.io/loiter/',
        repo: 'https://github.com/nmclear/loiter'
    },
    {
        title: 'Bamazon',
        image: Bamazon,
        summary: 'Bamazon is a CLI node application that serves as an Amazon-like storefront foe buying and selling goods. MySQL is used for data management. The inquirer package is used to handle user input. The application has two separate applications: one for customers and one for managers. Customers are allowed to shop for and purchase goods. Managers are allowed to add new products, update inventory, and view all inventory currently listed.',
        live: 'https://github.com/nmclear/bamazon',
        repo: 'https://github.com/nmclear/bamazon'
    },
    {
        title: 'Command Line Word Guess Game',
        image: WordGuess,
        summary: `Node CLI Word Guess Game. Test your sitcom knowledge with this command line word guess game. A TV sitcom title will be selected at random. You have 10 guesses to figure out the word or you lose. Check out the example video to see the app's functionality. The inquirer package is used to handle user input.`,
        live: 'https://drive.google.com/file/d/19uaIHhz5Ux7bEciBiMU08rndIj4K9FsQ/view?usp=sharing',
        repo: 'https://github.com/nmclear/constructor_word_guess_game'
    },
    {
        title: 'GIF Me Blazer',
        image: GIFBlazer,
        summary: `Find your GIFs!`,
        live: 'https://nmclear.github.io/GIF_Me_Blazer/',
        repo: 'https://github.com/nmclear/GIF_Me_Blazer'
    },
    {
        title: 'Friend Finder',
        image: FriendFinder,
        summary: `Friend Finder Full-Stack App`,
        live: 'https://nmclear-friend-finder.herokuapp.com/',
        repo: 'https://github.com/nmclear/friendfinder'
    },
    {
        title: 'Hogwarts Railways',
        image: Hogwarts,
        summary: `Wizarding World's Official Train Scheduler`,
        live: 'https://nmclear.github.io/hogwarts_railways/',
        repo: 'https://github.com/nmclear/hogwarts_railways'
    },
    {
        title: 'Brewing Trivia',
        image: BrewingTrivia,
        summary: `Test your beer knowledge!`,
        live: 'https://nmclear.github.io/brewing_trivia/',
        repo: 'https://github.com/nmclear/brewing_trivia'
    },
    {
        title: 'Bird Catcher Game',
        image: BirdCatcher,
        summary: `How many birds can you collect?`,
        live: 'https://nmclear.github.io/bird_catcher_game/',
        repo: 'https://github.com/nmclear/bird_catcher_game'
    },
    {
        title: 'LIRI Node App',
        image: Liri,
        summary: `SIRI but for the command line.`,
        live: 'https://github.com/nmclear/liri-node-app',
        repo: 'https://github.com/nmclear/liri-node-app'
    }



]

export default projects